@font-face {
  font-family: 'Ubuntu';
  src: url('../src/landingPage/assets/fonts/Ubuntu.ttf');
}

@font-face {
  font-family: 'Ubuntu-Bold';
  src: url('../src/landingPage/assets/fonts/Ubuntu-Bold.ttf');
}

@font-face {
  font-family: 'Ubuntu-Medium';
  src: url('../src/landingPage/assets/fonts/Ubuntu-Medium.ttf');
}

.drop-shadow {
  float: right;
}

.drop-shadow img {
  filter: drop-shadow(7px 7px 7px #666666);
  -webkit-filter: drop-shadow(7px 7px 7px #666666);
}
* {
  font-family: 'Ubuntu';
}

.pac-container{
  z-index: 2000;
}
